var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.headerShow),expression:"headerShow"}],staticClass:"header flex jc-b"},[_vm._m(0),_c('div',{staticClass:"right ss flex al-c"},[_c('div',{staticClass:"time ss flex-center",on:{"click":_vm.toMessageList}},[_c('i',{staticClass:"el-icon-bell msg"})]),_c('el-dropdown',{on:{"command":_vm.command}},[_c('div',{staticClass:"admin-name flex-center"},[_vm._v(" "+_vm._s(_vm.admin_name || "登录")+" "),_c('i',{staticClass:"el-icon-arrow-down down-icon"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"command":"user"}},[_vm._v("个人中心")]),_c('el-dropdown-item',{attrs:{"command":"quit"}},[_vm._v("退出")])],1)],1)],1)]),_c('el-menu',{directives:[{name:"show",rawName:"v-show",value:(_vm.menuShow),expression:"menuShow"}],staticClass:"aa",attrs:{"mode":"horizontal","default-active":_vm.cur,"unique-opened":"","text-color":"#434343","active-text-color":"#FF8813"}},_vm._l((_vm.navList),function(item){return _c('el-submenu',{key:item.id,attrs:{"index":item.id}},[_c('template',{slot:"title"},[_c('div',{staticClass:"top-meun",class:item.id == _vm.submenuIndex ? 'submenu-div' : '',on:{"click":function($event){return _vm.submenuindex(item.id)}}},[_c('i',{staticClass:"icon",class:[item.icon, item.id == _vm.submenuIndex ? 'icon-ac' : '']}),_c('span',[_vm._v(_vm._s(item.title))])])]),_vm._l((item.secNavL),function(nav,i){return _c('el-menu-item',{key:nav.url + nav.id,attrs:{"index":item.id + '-' + nav.id},on:{"click":function($event){return _vm.changeViewNav(
            { firstNav: item.title, secondNav: nav.secTitle },
            nav.url,
            { item: item.id, nav: nav.id }
          )}}},[_c('div',{staticClass:"tree-left"},[_c('div',{class:i == item.secNavL.length - 1 ? 'tree-half' : 'tree-h'}),_c('div',{staticClass:"tree-hr"},[_vm._v("—")]),_c('div',[_vm._v(_vm._s(nav.secTitle))])])])})],2)}),1),_c('el-container',{staticClass:"main"},[_c('el-aside',{directives:[{name:"show",rawName:"v-show",value:(_vm.menuShow),expression:"menuShow"}],staticClass:"bb",staticStyle:{"background-color":"#ffffff","width":"220px !important"}},[_c('el-menu',{staticClass:"el-menu-vertical-demo",attrs:{"default-active":_vm.cur,"background-color":"#ffffff","unique-opened":"","text-color":"#434343","active-text-color":"#FF8813"}},_vm._l((_vm.navList),function(item){return _c('el-submenu',{key:item.id + 'A',attrs:{"index":item.id}},[_c('template',{slot:"title"},[_c('div',{staticClass:"menu-wrap",class:item.id == _vm.submenuIndex ? 'submenu-div' : '',on:{"click":function($event){return _vm.submenuindex(item.id)}}},[_c('i',{staticClass:"icon",class:[item.icon, item.id == _vm.submenuIndex ? 'icon-ac' : '']}),_c('span',[_vm._v(_vm._s(item.title))]),(item.msg)?_c('div',{staticClass:"unread-tag"},[_vm._v(" "+_vm._s(item.msg > 99 ? "99+" : item.msg)+" ")]):_vm._e()])]),_vm._l((item.secNavL),function(nav,i){return _c('el-menu-item',{key:nav.url + nav.id,attrs:{"index":item.id + '-' + nav.id},on:{"click":function($event){return _vm.changeViewNav(
                { firstNav: item.title, secondNav: nav.secTitle },
                nav.url,
                { item: item.id, nav: nav.id }
              )}}},[_c('div',{staticClass:"tree-left"},[_c('div',{class:i == item.secNavL.length - 1 ? 'tree-half' : 'tree-h'}),_c('div',{staticClass:"tree-hr"},[_vm._v("—")]),_c('div',[_vm._v(_vm._s(nav.secTitle))])])])})],2)}),1)],1),_c('el-main',{class:_vm.headerShow ? 'bgcolor' : 'bgcolor-show'},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.headerShow),expression:"headerShow"}],staticClass:"top-tabs"},[_c('el-tabs',{attrs:{"type":"card","closable":""},on:{"tab-click":_vm.clickTab,"tab-remove":_vm.removetab},model:{value:(_vm.editableTabsValue),callback:function ($$v) {_vm.editableTabsValue=$$v},expression:"editableTabsValue"}},_vm._l((_vm.editableTabs),function(item,index){return _c('el-tab-pane',{key:item.content + index,attrs:{"label":item.title,"name":item.name}})}),1)],1),_c('keep-alive',{attrs:{"include":_vm.include}},[_c('router-view')],1)],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"flex al-c"},[_c('div',{staticStyle:{"margin-left":"17px"}},[_c('img',{staticStyle:{"width":"24px","height":"48px"},attrs:{"src":require("./assets/hm1.png")}})]),_c('div',{staticClass:"ss",staticStyle:{"font-size":"24px","padding-left":"20px"}},[_vm._v(" 共赢在海马 ")]),_c('img',{staticClass:"bb",staticStyle:{"width":"330px","height":"16px","margin-left":"20px","margin-top":"6px"},attrs:{"src":require("./assets/to-1.png")}})])])}]

export { render, staticRenderFns }