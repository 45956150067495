<template>
  <div>
    <div class="header flex jc-b" v-show="headerShow">
      <div>
        <div class="flex al-c">
          <div style="margin-left: 17px">
            <img style="width: 24px; height: 48px" src="./assets/hm1.png" />
          </div>
          <div style="font-size: 24px; padding-left: 20px" class="ss">
            共赢在海马
          </div>
          <img
            class="bb"
            src="./assets/to-1.png"
            style="
              width: 330px;
              height: 16px;
              margin-left: 20px;
              margin-top: 6px;
            "
          />
        </div>
      </div>
      <div class="right ss flex al-c">
        <div class="time ss flex-center" @click="toMessageList">
          <i class="el-icon-bell msg"></i>
        </div>

        <el-dropdown @command="command">
          <div class="admin-name flex-center">
            {{ admin_name || "登录" }}
            <i class="el-icon-arrow-down down-icon"></i>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="user">个人中心</el-dropdown-item>
            <el-dropdown-item command="quit">退出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <el-menu
      v-show="menuShow"
      class="aa"
      mode="horizontal"
      :default-active="cur"
      unique-opened
      text-color="#434343"
      active-text-color="#FF8813"
    >
      <el-submenu :index="item.id" v-for="item in navList" :key="item.id">
        <template slot="title">
          <div
            @click="submenuindex(item.id)"
            :class="item.id == submenuIndex ? 'submenu-div' : ''"
            class="top-meun"
          >
            <i
              :class="[item.icon, item.id == submenuIndex ? 'icon-ac' : '']"
              class="icon"
            ></i>
            <span>{{ item.title }}</span>
          </div>
        </template>

        <el-menu-item
          :index="item.id + '-' + nav.id"
          v-for="(nav, i) in item.secNavL"
          @click="
            changeViewNav(
              { firstNav: item.title, secondNav: nav.secTitle },
              nav.url,
              { item: item.id, nav: nav.id }
            )
          "
          :key="nav.url + nav.id"
        >
          <div class="tree-left">
            <div
              :class="i == item.secNavL.length - 1 ? 'tree-half' : 'tree-h'"
            ></div>
            <div class="tree-hr">—</div>
            <div>{{ nav.secTitle }}</div>
          </div>
        </el-menu-item>
      </el-submenu>
    </el-menu>

    <el-container class="main">
      <el-aside
        v-show="menuShow"
        class="bb"
        style="background-color: #ffffff; width: 220px !important"
      >
        <el-menu
          :default-active="cur"
          background-color="#ffffff"
          unique-opened
          text-color="#434343"
          active-text-color="#FF8813"
          class="el-menu-vertical-demo"
        >
          <el-submenu
            :index="item.id"
            v-for="item in navList"
            :key="item.id + 'A'"
          >
            <template slot="title">
              <div
                @click="submenuindex(item.id)"
                :class="item.id == submenuIndex ? 'submenu-div' : ''"
                class="menu-wrap"
              >
                <i
                  :class="[item.icon, item.id == submenuIndex ? 'icon-ac' : '']"
                  class="icon"
                ></i>
                <span>{{ item.title }}</span>
                <div v-if="item.msg" class="unread-tag">
                  {{ item.msg > 99 ? "99+" : item.msg }}
                </div>
              </div>
            </template>
            <!-- <div> -->
            <!-- v-show="nav.secTitle != '消费金列表' && mobile != '18658876977'" -->
            <el-menu-item
              :index="item.id + '-' + nav.id"
              v-for="(nav, i) in item.secNavL"
              @click="
                changeViewNav(
                  { firstNav: item.title, secondNav: nav.secTitle },
                  nav.url,
                  { item: item.id, nav: nav.id }
                )
              "
              :key="nav.url + nav.id"
            >
              <div class="tree-left">
                <div
                  :class="i == item.secNavL.length - 1 ? 'tree-half' : 'tree-h'"
                ></div>
                <div class="tree-hr">—</div>
                <div>{{ nav.secTitle }}</div>
              </div>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>

      <el-main :class="headerShow ? 'bgcolor' : 'bgcolor-show'">
        <div class="top-tabs" v-show="headerShow">
          <el-tabs
            v-model="editableTabsValue"
            type="card"
            @tab-click="clickTab"
            closable
            @tab-remove="removetab"
          >
            <el-tab-pane
              v-for="(item, index) in editableTabs"
              :key="item.content + index"
              :label="item.title"
              :name="item.name"
            >
            </el-tab-pane>
          </el-tabs>
        </div>
        <keep-alive :include="include">
          <!-- 需要缓存的视图组件 -->
          <router-view> </router-view>
        </keep-alive>
      </el-main>
    </el-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      submenuIndex: 1,
      cur: "",
      activeName: 0,
      editableTabsValue: "1",
      tabIndex: "1",
      editableTabs: [],
      timer: "",
      currentTime: "正在获取服务器时间",
      if_show_log: true,
      currentNavId: { first: 0, second: 0 },
      count: 0,
      baseUrl: "线上",
      // navList: [],
      include: [],
      managerInfo: {},
      msg: 0,
    };
  },
  watch: {
    $route: {
      handler: function (to, from) {
        //如果 要 to(进入) 的页面是需要 keepAlive 缓存的，把 name push 进 include数组
        if (to.meta.keepAlive) {
          !this.include.includes(to.name) && this.include.push(to.name);
        }
      },
      immediate: true,
    },
  },
  computed: {
    navList() {
      let menu = this.$store.state.menuList;
      if (menu.length <= 0) {
        menu = JSON.parse(localStorage.getItem("menu"));
      }
      for (let i = 0; i < menu.length; i++) {
        if (menu[i].title == "用户消息" || menu[i].title == "消息中心") {
          menu[i].msg = this.msg;
        }
      }
      return menu;
    },
    headerShow() {
      return this.$store.state.headerShow;
    },
    menuShow() {
      return this.$store.state.menuShow;
    },
    admin_name() {
      return this.$store.state.admin_name;
    },
    timLogin() {
      return this.$store.state.timLogin;
    },
  },
  mounted() {
    if (localStorage.getItem("token")) {
      this.$store.dispatch("getUserPermission");
      this.$store.dispatch("getRoleMenu");
      this.$store.dispatch("getCateList");
      this.managerInfo = JSON.parse(localStorage.getItem("managerInfo") || '{}');
      if(this.managerInfo.id){
        this.getAdminInfo()
      }
      this.$tim.on(
          this.$tims.EVENT.TOTAL_UNREAD_MESSAGE_COUNT_UPDATED,
          this.getMessageReceived
        );
        this.$tim.on(this.$tims.EVENT.KICKED_OUT, this.handleKickedOut);
        this.$tim.on(this.$tims.EVENT.SDK_READY, this.handleSDKReady);
      if (localStorage.getItem("timSign") && this.managerInfo.user_id) {
        if (this.timLogin) {
          this.getMessageReceived();
        } else {
          this.$tim
            .login({
              userID: "" + this.managerInfo.user_id,
              userSig: localStorage.getItem("timSign"),
            })
            .then((res) => {
              this.$store.commit("timLogin", true);
            });
        }
      } else if(this.managerInfo.user_id) {
        this.getTimSign();
      }
    }
  },

  methods: {
     getAdminInfo() {
      this.$axios({
        url: "/authorization/managerUser/findById",
        params: {
          id: this.managerInfo.id,
        },
      }).then((res) => {
            localStorage.setItem("managerInfo", JSON.stringify(res.data.data));
      });
    },
    //获取自己的消息
    getUserInfo() {
      this.$tim.getMyProfile().then((res) => {
        sessionStorage.setItem("user", JSON.stringify(res.data));
      });
    },
    handleKickedOut(event) {
				console.log("KickedOut----", event.data.type);
        this.$confirm("消息中心登录过期，请重新登录").then(()=>{
         this.logout()
        })
				switch (event.data.type) {
					case this.$tims.TYPES.KICKED_OUT_MULT_ACCOUNT:
						return "多实例登录";
					case this.$tims.TYPES.KICKED_OUT_MULT_DEVICE:
						return "多设备登录";
					case this.$tims.TYPES.KICKED_OUT_USERSIG_EXPIRED:
						return "userSig 过期";
					case this.$tims.TYPES.KICKED_OUT_REST_API:
						return "REST API kick 接口踢出";
					default:
						return "";
				}
        
			},
    // 挂载完毕
    handleSDKReady() {
      this.getUserInfo();
    },
    getMessageReceived() {
      let count = this.$tim.getTotalUnreadMessageCount();
      if( typeof count == 'number'){
      this.msg = count;
      }
    },
    getTimSign() {
      this.$get("/user/user/getSign", {
        user_id: this.managerInfo.user_id,
      }).then((res) => {
        localStorage.setItem("timSign", res.data.data);
         this.$tim
            .login({
              userID: "" + this.managerInfo.user_id,
              userSig: localStorage.getItem("timSign"),
            })
            .then((res) => {
              this.$store.commit("timLogin", true);
            });
      });
    },
    command(com) {
      switch (com) {
        case "user":
          this.toInfoCenter();
          break;
        case "quit":
          this.logout();
          break;
        default:
          break;
      }
    },

    toInfoCenter() {
      this.$router.push("/main/admininfoCenter");
    },
    toMessageList() {
      this.$router.push("/main/MessageList");
    },
    //当前route的菜单
    current_subMenu() {
      let list = this.navList;
      let url = this.$route.path.replace("/main", "");
      if (list.length > 0) {
        for (let i = 0; i < list.length; i++) {
          let subMenu = list[i].secNavL;
          for (let j = 0; j < subMenu.length; j++) {
            if (subMenu[j].url == url) {
              this.cur = `${list[i].id}-${subMenu[j].id}`;
              this.submenuIndex = list[i].id;
              this.editableTabs = [
                {
                  title: subMenu[j].secTitle,
                  name: "1",
                  content: `${list[i].id}-${subMenu[j].id}`,
                  routerly: subMenu[j].url,
                  id: list[i].id,
                },
              ];
              return;
            }
          }
        }
      }
    },
    submenuindex(e) {
      this.submenuIndex = e;
    },
    // aaa(){
    //   console.log(123);
    // },
    removetab(e) {
      if (this.editableTabs.length != 1) {
        this.editableTabs = this.editableTabs.filter((tab) => tab.name !== e);
        this.editableTabsValue =
          this.editableTabs[this.editableTabs.length - 1].name;
        this.cur = this.editableTabs[this.editableTabs.length - 1].content + "";
        this.$router.push(
          "/main" + this.editableTabs[this.editableTabs.length - 1].routerly
        );
      }
    },
    clickTab(tabb) {
      let t = this.editableTabs.filter((tab) => tab.name == tabb.name);
      this.cur = t[0].content;
      this.submenuIndex = t[0].id;
      let navUrl = "/main" + t[0].routerly;
      this.$router.push(navUrl);
    },
    show() {
      this.if_show_log = !this.if_show_log;
    },
    load() {
      this.count++;
    },
    getArrayValue(arr, id) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].id == id) {
          return arr[i];
        }
      }
      return undefined;
    },
    // console.log(this.currentNavId.first);
    changeViewNav(nav, url, a) {
      if (url) {
        if (url == "/timHome") {
          this.msg = 0;
        }
        let navUrl = "/main" + url;
        this.$router.push(navUrl);
      }
      let aaa = this.editableTabs.filter(
        (e) =>
          e.routerly ==
          this.getArrayValue(
            this.getArrayValue(this.navList, a.item).secNavL,
            a.nav
          ).url
        // this.navList[a.item - 1].secNavL[a.nav].url
      );
      if (aaa.length == 0) {
        let newTabName = ++this.tabIndex + "";
        this.editableTabs.push({
          title: this.getArrayValue(
            this.getArrayValue(this.navList, a.item).secNavL,
            a.nav
          ).secTitle,
          name: newTabName,
          content: a.item + "-" + a.nav + "",
          routerly: this.getArrayValue(
            this.getArrayValue(this.navList, a.item).secNavL,
            a.nav
          ).url,
          id: this.getArrayValue(this.navList, a.item).id,
        });
        this.editableTabsValue = newTabName;
        // this.cur = this.editableTabs[Number(this.editableTabsValue) - 1].content + "";
        this.cur = this.editableTabs[this.editableTabs.length - 1].content + "";
      } else {
        for (let i = 0; i < this.editableTabs.length; i++) {
          const element = this.editableTabs[i];
          if (
            element.routerly ==
            this.getArrayValue(
              this.getArrayValue(this.navList, a.item).secNavL,
              a.nav
            ).url
          ) {
            this.editableTabsValue = i + 1 + "";
          }
        }
      }
    },
    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/login");
      this.$tim.logout();
      localStorage.removeItem("timSign");
      this.$store.commit("timLogin", false);
    },
    // changeBaseUrl(v) {
    //   console.log(v)
    //   if (v == '本地') {
    //     this.$axios.defaults.baseURL = 'http://192.168.1.111:8768/eg-api'
    //     localStorage.setItem('baseUrl', '本地')
    //   } else if (v == '测试') {
    //     this.$axios.defaults.baseURL = 'http://139.186.169.185:8768/eg-api'
    //     localStorage.setItem('baseUrl', '测试')
    //   } else if (v == '线上') {
    //     this.$axios.defaults.baseURL = 'https://www.curiousmore.com/qmore/' /* 线上 */
    //     localStorage.setItem('baseUrl', '线上')
    //   }
    //   this.$forceUpdate()
    // },
  },
  created() {
    var _this = this;
    this.$store.dispatch("getDistrictList");

    this.timer = setInterval(function () {
      let currentTime = new Date().Format("yyyy-MM-dd hh:mm:ss");
      _this.currentTime = currentTime;
    }, 1000);

    this.baseUrl = localStorage.getItem("baseUrl");
  },
  beforeDestroy() {
    this.$tim.off(
      this.$tims.EVENT.TOTAL_UNREAD_MESSAGE_COUNT_UPDATED,
      this.getMessageReceived
    );
      this.$tim.on(this.$tims.EVENT.KICKED_OUT, this.handleKickedOut);
    this.$tim.off(this.$tims.EVENT.SDK_READY, this.handleSDKReady);
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
};
</script>
<style lang="less">
.icon1 {
  width: 18px;
  height: 21px;
  color: white;
}

.quit {
  font-size: 16px;
  margin-right: 4px;
}

.top-tabs {
  margin-bottom: 20px;
}
.top-tabs .el-tabs .el-tabs__header {
  margin: 0;
}

.top-meun {
  padding: 0 10px;
}

.submenu-div {
  background-color: #ffaf61;
  color: #ffffff;
}

.bgcolor {
  margin-left: 20px;
  margin-top: 0;
}
.bgcolor-show {
  margin: 0;
}
.el-submenu__icon-arrow {
  right: 30px !important;
}

.header {
  color: #fff;
  height: 70px;
  align-items: center;
  background: linear-gradient(90deg, #ff7e00 0%, #ffaf61 100%);
}

.el-menu {
  width: 220px;
  overflow: hidden;
}

.el-submenu__title:hover {
  background-color: #fff7f1 !important;
}

.el-submenu__title {
  padding: 0px !important;
}

.el-aside .el-submenu .el-menu-item:hover {
  background-color: #faeee4 !important;
  border-left: 5px #ff7e00 solid;
  color: #eb8b2b !important;
}

.el-aside .el-submenu .el-menu-item {
  border-left: 5px #ff7e00 solid;
  background-color: #ffffff !important;
}

.admin-name {
  font-size: 16px;
  margin: 0 10px;
  color: #fff;
}

.aa {
  width: 100vw;
  display: none;
  overflow: scroll;

  @media screen and(max-width: 768px) {
    display: flex;
  }
}

.bb {
  display: block;

  @media screen and(max-width: 768px) {
    display: none;
  }
}

.icon {
  font-size: 22px;
  margin-right: 5px;
}

.tree-left {
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  // align-items: center;
  align-content: center;
}

.tree-h {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 1px;
  background-color: #cccccc;
}

.tree-half {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 52%;
  width: 1px;
  background-color: #cccccc;
}

.tree-hr {
  margin-right: 8px;
  color: #cccccc;
  font-size: 12px;
}

.el-menu-item {
  border-left: 4px solid #ff7e00;
}

.icon-ac {
  color: #fff !important;
  // background-color: #fff;
}

.right {
  margin-right: 1rem;
}

.msg {
  font-size: 24px;
  margin-right: 10px;
  color: #fff;
}
.down-icon {
  margin-left: 8px;
  padding-top: 4px;
}

.menu-wrap {
  padding-left: 30px;
  position: relative;
  .unread-tag {
    position: absolute;
    top: 15px;
    right: 45px;
    font-size: 12px;
    background-color: #e84a34;
    color: #f0f0f0;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
  }
}
</style>
